import Button from '@mui/material/Button';
import "./DailyOperations.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import OperationAdding from './AddingOperation'
import { NotificationContainer } from 'react-notifications';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, FormControl, InputLabel, Select, MenuItem, Stack} from "@mui/material";
import { Link } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";



const ListingDailyOperations = () => {

    const [dailyOperations, setDailyOperations] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [userDetails, setUserDetails] = useState({ user: { is_staff: false } })
    const [doctors, setDoctors] = useState([])
    const [selectedDoctor, setSelectedDoctor] = useState(null)
    const [totalSum, setTotalSum] = useState("")

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        axios.get(baseUrl + '/accounts/details/', { headers: headers })
            .then(function (response) {
                setUserDetails(response.data)
                console.log(response.data.user.is_staff)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });

    }, [])

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        let date_created = new Date(selectedDate)
        let date_formated = `${date_created.getFullYear()}-${date_created.getMonth()}-${date_created.getDate()}`

        axios.get(baseUrl + '/daily/operations/?date=' + date_formated, { headers: headers })
            .then(function (response) {
                let arr = []
                let total = 0;
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let date = new Date(element.created_at)
                    element.created_at = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
                    arr.push(element)

                    total += element.count * element.price

                }
                setDailyOperations(arr)
                setTotalSum(total)
                console.log(totalSum)


            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });

    }, [selectedDate])

    const cheangeUserDisplay = (doc) => {

        setSelectedDoctor(doc)
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        let date_created = new Date(selectedDate)
        let date_formated = `${date_created.getFullYear()}-${date_created.getMonth()}-${date_created.getDate()}`

        axios.get(baseUrl + '/daily/operations-admin/?date=' + date_formated + '&doctor=' + doc, { headers: headers })
            .then(function (response) {
                let arr = []
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let date = new Date(element.created_at)
                    element.created_at = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
                    arr.push(element)

                }
                setDailyOperations(arr)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });

    }
    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        axios.get(baseUrl + '/accounts/get-employees/')
            .then(function (response) {
                //console.log(response.data, headers)
                const arr_docs = []
                response.data.forEach(element => {
                    let obj_doc
                    if (element.role == "doctor") {
                        obj_doc = {
                            id: element.id,
                            text: "д-р " + element.user.first_name,
                            color: element.color
                        }
                    }
                    else {
                        obj_doc = {
                            id: element.id,
                            text: element.user.first_name,
                            color: element.color
                        }
                    }
                    arr_docs.push(obj_doc)
                });

                setDoctors(arr_docs)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date)
    }

    const handleDeleteItem = (id) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        let date_created = new Date(selectedDate)
        let date_formated = `${date_created.getFullYear()}-${date_created.getMonth()}-${date_created.getDate()}`

        axios.delete(baseUrl + '/daily/operations/?id='+id, { headers: headers })
            .then(function (response) {
                window.location.reload();
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });

    }


const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'operation', headerName: 'Операција', width: 230 },
    { field: 'count', headerName: 'Количина', width: 130 },
    { field: 'created_at', headerName: 'Датум', width: 130 },
    { field: 'employee', headerName: 'Креирано од', width: 130, valueGetter: (params) => params.user.first_name },
    { field: 'price', headerName: 'Цена', width: 130 },
    {
        field: 'total',
        headerName: "Збир",
        width: 160,
        sortable: false,
        renderCell: (params) => {
            return (<>{params.row.count * params.row.price}</>);
        }
    },
    {
        field: "Delete",
        headerName: "Избриши",
        width: 145,
        sortable: false,
        renderCell: (params) => {
          return (
            <Stack spacing={2} direction="row">
              <Button
                onClick={() => handleDeleteItem(params.id)}
                variant="contained"
                color="error"
              >
                <RemoveIcon />
              </Button>
            </Stack>
          );
        },
      },

];



    return (
        <>

            <div id="main">
                <div>
                    <div className="add-button">
                        <Button variant="contained" color="success" onClick={handleOpen}>Додади активност</Button>

                        {userDetails.user.is_staff === true &&
                            <Link to={"/statistika-operacii/"} className="stats">Статистика дневни операции</Link>
                        }

                    </div>
                    <div className="date-filter" style={{ marginTop: "20px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} className='time-inputs' id="calendar-schedule">
                            <DatePicker
                                style={{ marginTop: "105px" }}
                                label="Датум"
                                ampm={false}
                                value={selectedDate}
                                onChange={(event) => handleDateChange(new Date(event).toISOString())}
                                renderInput={(params) => <TextField {...params} />}
                                id="calendar-schedule"
                                className='time-inputs'
                            /></LocalizationProvider>

                        {userDetails.user.is_staff === true &&
                            <FormControl sx={{ width: 400, height: 80, paddingBottom: 2 }} className='doctor-select'>
                                <InputLabel id="doctor-select-label"  >Вработен</InputLabel>
                                <Select
                                    labelId="doctor-select-label"
                                    id="demo-simple-select"
                                    value={selectedDoctor}
                                    name='operationName'
                                    label=""
                                    onChange={(e) => cheangeUserDisplay(e.target.value)}

                                >
                                    {doctors.map((ch, index) => (
                                        <MenuItem key={index} value={ch.id}>
                                            {ch.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }

                    </div>
                </div>

                <div className="table-with-operations">
                    <div style={{ marginTop: '20px' }}>
                        <DataGrid
                            rows={dailyOperations}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 20 },
                                },
                                aggregation: {
                                    model: {
                                        price: 'sum',
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 20, 50, 100]}

                        />
                    </div>

                </div>
                <div style={{ textAlign: "right" }}>
                    <h3 >Вкупно: {totalSum},00</h3>
                </div>
            </div>
            <OperationAdding open={open} handleClose={handleClose} />
            <NotificationContainer />
        </>

    )
}

export default ListingDailyOperations;