import Navbar from "../NavBar";
import "./StatsDailyOperations.css"
import StatsCard from "./StatsCard"
import { useState, useEffect } from "react";
import axios from "axios";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem, Grid, Box } from "@mui/material";

const StatsDailyOperations = () => {

    const [statsData, setStatsData] = useState([])
    const [timeframe, setTimeFrame] = useState([7])
    const [doctors, setDoctors] = useState([])
    const [selectedDoctor, setSelectedDoctor] = useState(null)
    const [userDetails, setUserDetails] = useState({ user: { is_staff: false } })


    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        axios.get(baseUrl + '/accounts/get-employees/')
            .then(function (response) {
                //console.log(response.data, headers)
                const arr_docs = []
                response.data.forEach(element => {
                    let obj_doc
                    if (element.role == "doctor") {
                        obj_doc = {
                            id: element.id,
                            text: "д-р " + element.user.first_name,
                            color: element.color
                        }
                    }
                    else {
                        obj_doc = {
                            id: element.id,
                            text: element.user.first_name,
                            color: element.color
                        }
                    }
                    arr_docs.push(obj_doc)
                });

                setDoctors(arr_docs)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });
    }, []);


    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };
        axios.get(baseUrl + '/accounts/details/', { headers: headers })
            .then(function (response) {
                setUserDetails(response.data)
                console.log(response.data.user.is_staff)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });

    }, [])


    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;
        const headers = {
            'Authorization': `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
            'Content-Type': 'application/json', // Adjust content type as needed
        };


        axios.get(baseUrl + `/daily/get-daily-stats/?timeframe=${timeframe}&emp_id=${selectedDoctor}`, { headers: headers })
            .then(function (response) {
                setStatsData(response.data)
            })
            .catch(function (error) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error
                    console.error("Unauthorized access - 401");
                    window.location.href = "/login";
                    // Perform any specific action such as redirecting to login
                } else {
                    // Handle other errors
                    console.error("An error occurred:", error);
                }
            });
    }, [timeframe, selectedDoctor])



    const handleDateChange = (event, newAlignment) => {
        setTimeFrame(newAlignment);
    };

    const generateStartDate = () => {
        let dateNow = new Date()
        return `${dateNow.getUTCDate()}.${dateNow.getMonth() + 1}.${dateNow.getFullYear()}`
    }

    const generateEndDate = () => {
        let newDate = new Date();
        let currentTime = newDate.getTime();
        let millisecondsInDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        let subtractTime = timeframe * millisecondsInDay;
        newDate.setTime(currentTime - subtractTime);
        return `${newDate.getUTCDate()}.${newDate.getMonth() + 1}.${newDate.getFullYear()}`
    }


    return (
        <>
            <Grid container >

                <Grid ietm xs={12} >

                    <div id="main">
                        <h1 style={{ textAlign: "center" }}>Статистика за дневни обврски</h1>
                        <div className="timeframe-choices">
                            <Box justifyContent={"space-around"} style={{ display: "flex" }}>
                                <Box >
                                    <h4>Податоци за период:</h4>
                                    <p> {generateStartDate()} - {generateEndDate()}</p>

                                    <ToggleButtonGroup
                                        value={timeframe}
                                        exclusive
                                        onChange={handleDateChange}
                                        aria-label="date range"
                                        className="date-ranges"
                                    >
                                        <ToggleButton value={7} aria-label="left aligned">
                                            7Д
                                        </ToggleButton>
                                        <ToggleButton value={30} aria-label="centered">
                                            30Д
                                        </ToggleButton>
                                        <ToggleButton value={90} aria-label="right aligned">
                                            3М
                                        </ToggleButton>
                                        <ToggleButton value={0} aria-label="justified">
                                            Почеток
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                </Box>

                                <Box sx={{ paddingTop: "82px" }}>
                                    {userDetails.user.is_staff === true &&
                                        <FormControl sx={{ width: 400, height: 80 }} className='doctor-select'>
                                            <InputLabel id="doctor-select-label"  >Вработен</InputLabel>
                                            <Select
                                                labelId="doctor-select-label"
                                                id="demo-simple-select"
                                                value={selectedDoctor}
                                                name='operationName'
                                                label=""
                                                onChange={(e) => setSelectedDoctor(e.target.value)}

                                            >
                                                {doctors.map((ch, index) => (
                                                    <MenuItem key={index} value={ch.id}>
                                                        {ch.text}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                </Box>
                            </Box>



                        </div>

                        <div id="stats">
                            {statsData.map((emp, key) =>
                                <div key={key}>
                                    <h2>{emp.doctor}</h2>
                                    <StatsCard statsData={emp.data} />
                                    <hr></hr>
                                </div>
                            )}
                        </div>
                    </div>

                </Grid>
            </Grid>



        </>
    )
}

export default StatsDailyOperations;